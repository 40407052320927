@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  h1 {
    @apply text-2xl font-bold text-center py-10;
  }

  .btn_blue {
    @apply border p-2 rounded-md hover:bg-blue-500 hover:text-white hover:cursor-pointer;
  }
  .btn_red {
    @apply border p-2 rounded-md hover:bg-red-500 hover:text-white hover:cursor-pointer;
  }
  .btn_green {
    @apply border p-2 rounded-md hover:bg-green-700 hover:text-white hover:cursor-pointer;
  }

  .div_outer {
    @apply flex justify-center select-none;
  }
  .div_inner {
    @apply w-1/2 mx-auto;
  }

  .checkbox {
    @apply px-2 rounded focus:outline-none focus:ring-transparent focus:border-slate-500 cursor-pointer;
  }
  .input {
    @apply mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6;
  }

  .label_block {
    @apply block text-sm font-medium leading-6 text-gray-900;
  }
  .label_inline {
    @apply text-sm font-medium leading-6 text-gray-900;
  }
}
